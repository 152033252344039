<template>
  <div class="parent">
    <lab>
      <template #content>
        <steps :mod="mod" v-show="$route.path.startsWith('/parent')"></steps>
      </template>
    </lab>
    <router-view></router-view>
  </div>
</template>
<script>
import Lab from "../components/Lab"
import Steps from "../components/Steps"
export default {
  props:["mod"],
  components:{Lab, Steps},
  mounted(){
    console.log(this.$route.path);
    console.log(this.mod);
  }
}
</script>
<style>
.parent{
  width: 1110px;
  margin: 0 auto;
  padding-bottom:100px;
  overflow:hidden;
}
</style>