<template>
  <div class="steps">
    <ul>
      <div>
        <el-drawer
          :size="800"
          :visible.sync="drawer"
          :before-close="handleClose"
          :modal="false"
        >
          <iframe
            class="ifr"
            :src="`https://lab.ibrpa.com/docs#/zh-cn/ibrpa/${mod}/${mod}`"
          ></iframe>
        </el-drawer>
      </div>
      <br />
      <li v-for="(s, i) of steps" :key="i">
        <span>第{{ i + 1 }}步</span><span v-html="s"></span>
      </li>
    </ul>
    <button class="jiao" @click="drawer = true" type="primary"></button>
  </div>
</template>
<script>
import steps from "../assets/js/apis/steps";
export default {
  props: ["mod"],
  data() {
    return {
      drawer: false,
      steps: [],
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
  },
  mounted() {
    steps
      .getSteps(
        this.$route.path.startsWith("/parent") ? this.mod : this.$route.path
      )
      .then((result) => {
        this.steps = result.data.map((item) => item.step);
        console.log(this.mod);
        console.log(this.steps);
      });
  },
};
</script>
<style>
.jiao {
  width: 150px;
  height: 80px;
  cursor: pointer;
  display: block;
  background: url("../assets/images/8.png");
  background-size: 100%;
  border: none;
}
.steps > ul > li > span > .last {
  width: 150px;
  height: 80px;
  cursor: pointer;
  display: inline-block;
  background: url("../assets/images/9.png");
  background-size: 100%;
  border: none;
  margin: -30px -5px -32px -65px;
}

.ifr {
  width: 100%;
  height: 100%;
}
.steps a {
  color: #0052d9;
}
.steps > ul > li + li {
  margin-top: 32px;
}
.steps > ul > li > span:last-child {
  height: 19px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.steps > ul > li > span:first-child {
  width: 47px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-right: 19px;
}
.steps > ul > li > span > a {
  margin-left: 16px;
}
</style>