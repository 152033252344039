<template>
  <div class="lab">
    <h1>{{ title }}</h1>
    <div>
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex"
export default {
  computed:{
    ...mapState(["title"])
  }
};
</script>
<style>
.lab {
  padding-top: 23px;
}
.lab > h1 {
  display: inline-block;
  height: 36px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  padding-bottom: 9px;
  border-bottom: 4px solid #146dff;
}
.lab>div{
  border-top:1px solid #ddd;
  padding-top:41px;
}
</style>